import { lazy } from "react";
import i18n from "../../i18n";

import {
  BookOutlined,
  UnorderedListOutlined,
  UserSwitchOutlined,
  MergeCellsOutlined,
  TeamOutlined,
  LoginOutlined,
  MacCommandOutlined,
  FontColorsOutlined,
  SettingOutlined,
  BuildOutlined,
  LinkOutlined,
  EditOutlined,
} from "@ant-design/icons";

const options = [
  {
    key: Math.random(),
    path: "/AppVariables",
    component: lazy(() => import("../appVariables/DBModel")),
    exact: true,
    name: i18n.t("appVariables"),
    icon: FontColorsOutlined,
    render: true,
  },
  {
    key: Math.random(),
    path: "/HomeSlider",
    component: lazy(() => import("../topHomeSlider/List")),
    exact: true,
    name: i18n.t("Top Slider"),
    icon: BookOutlined,
    render: true,
  },
  // {
  //     key: Math.random(),
  //     path: '/HomeMiddleSlider',
  //     component: lazy(() => import('../middleHomeSlider/List')),
  //     exact: true,
  //     name: i18n.t('Middle Home Slider'),
  //     icon: BookOutlined,
  //     render: true
  // },
  {
    key: Math.random(),
    path: "/CoverageSlider",
    component: lazy(() => import("../coverageSlider/List")),
    exact: true,
    name: i18n.t("Coverage Slider"),
    icon: BookOutlined,
    render: true,
  },
  {
    key: Math.random(),
    path: "/Guaranteed",
    component: lazy(() => import("../guaranteed/List")),
    exact: true,
    name: i18n.t("Guaranteed"),
    icon: MergeCellsOutlined,
    render: true,
  },
  {
    key: Math.random(),
    path: "/Services",
    component: lazy(() => import("../service/List")),
    exact: true,
    name: i18n.t("Services"),
    icon: SettingOutlined,
    render: true,
  },
  {
    key: Math.random(),
    path: "/Branch",
    component: lazy(() => import("../branch/List")),
    exact: true,
    name: i18n.t("Branch"),
    icon: BuildOutlined,
    render: true,
  },
  {
    key: Math.random(),
    path: "/Tag",
    component: lazy(() => import("../tags/List")),
    exact: true,
    name: i18n.t("Tag"),
    icon: LinkOutlined,
    render: true,
  },
  // {
  //     key: Math.random(),
  //     path: '/Feature',
  //     component: lazy(() => import('../feature/List')),
  //     exact: true,
  //     name: i18n.t('Feature And Benefits'),
  //     icon: EditOutlined,
  //     render: true
  // },
  {
    key: Math.random(),
    path: "/CorporateNews",
    component: lazy(() => import("../corporateNews/List")),
    exact: true,
    name: i18n.t("Corporate News"),
    icon: UnorderedListOutlined,
    render: true,
  },
  {
    key: Math.random(),
    path: "/Positions",
    component: lazy(() => import("../careers/List")),
    exact: true,
    name: i18n.t("Careers"),
    icon: TeamOutlined,
    render: true,
  },
  {
    key: Math.random(),
    path: "/Applications/:id",
    component: lazy(() => import("../careers/Applications")),
    exact: true,
    name: i18n.t("Careers"),
    icon: TeamOutlined,
    render: false,
  },
  // {
  //     key: Math.random(),
  //     path: '/CorporateSlider',
  //     component: lazy(() => import('../corporateSlider/List')),
  //     exact: true,
  //     name: i18n.t('Corporate Slider'),
  //     icon: UnorderedListOutlined,
  //     render: true
  // },

  // {
  //     key: Math.random(),
  //     path: '/Activities',
  //     component: lazy(() => import('../activity/List')),
  //     exact: true,
  //     name: i18n.t('Activity'),
  //     icon: MergeCellsOutlined,
  //     render: true
  // },
];
export default options;
